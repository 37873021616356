
<template>
  <v-tooltip bottom v-if="exemption">
        <template v-slot:activator="{ on, attrs }">
          <td 
            :class="statusColor"
            v-bind="attrs"
            v-on="on"
          >
          <span>Exemption</span></td>
        </template>
      <span>{{ this.exemption_option }}</span>
      </v-tooltip>
  <td 
  :class="statusColor" v-else>
    {{ this.statusText }}
  </td>
</template>

<script>

import {useProjectsStore} from '@/store/Projects.js'

export default {
  name: "historyCell",
  setup() {
      // create a constant for the store being used, and return it
        const projectStore = useProjectsStore() 
        return { projectStore }
    },
  props: {
    value: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      statusColor: "",
      statusText: "",
      exemption: false,
      exemption_option: ""
    }
  },
  computed: {
  },
  methods: {
    monthlyStatus() {
      if(this.value.exemption_option){
        this.statusColor = "status-partial"
        this.exemption_option = this.value.exemption_option
        this.exemption = true
        return
      }
      let current_list = this.value.instrument_list
      if (current_list[0].inst_name == null){
        this.statusColor = "status-closed"
        this.statusText = "Pending"
        return
        }
      let success = 0
      for(let i=0; i<current_list.length; i++){
          if(current_list[i].inst_status == 'Validated'){
            success += 1
          }
        }
      let totalInst = current_list.length
      if(success == totalInst){
        this.statusColor = "status-success"
        this.statusText = `${success}/${totalInst}`
        }else{
          this.statusColor = "status-partial"
          this.statusText = `${success}/${totalInst}`
          if(success == 0){
            this.statusColor = "status-error"
          }
        }
      },
      checkClosed(guid) {
        let project = this.projectStore.projects.find(project => project.project_guid == guid)
        if(project.close_date && new Date(project.close_date) < Date.parse(this.value.period)){
          this.statusColor = "status-closed"
          this.statusText = "Closed"
        }else{
          this.monthlyStatus()
        }  
      }
  },
  mounted() {
    this.checkClosed(this.value.project_guid)
  },
}
</script>

<style scoped>
  .status-success {
    background-color: rgb(128, 187, 128);
  }
  .status-error {
    background-color: rgb(236, 124, 124);
  }
  .status-closed {
    background-color: rgb(192, 192, 192);
  }
  .status-partial {
    background-color: rgb(255, 255, 129);
  }
</style>

import {defineStore} from 'pinia'
import VueJwtDecode from 'vue-jwt-decode'
import router from '@/router'

export const useUserStore = defineStore({
    id:'user',
    inject: [],
    state: () =>({
        token: '',
        loggedIn: false,
    }),
    getters: {},
    actions: {
        async userLogin(userpass) {
        try {
            let resp = await fetch(process.env.VUE_APP_API_URL + 'auth/login', {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': userpass
                }),
            })
            let tokenobj = await resp.json()
            this.token = tokenobj['token']
            if(resp.status == 200){
                //set token to local storage
                localStorage.setItem('cifasd_token', this.token)
                this.loggedIn = true
                return this.token
            }else{
                return false
            }
        } catch (error) {
            // console.log(error)
            return false
        }
    },
    userLogout(){
        this.token = ''
        this.loggedIn = false
        localStorage.clear()
        router.push('/')
        return
    },  
    checkToken(){
        let saved_token = localStorage.getItem('cifasd_token')

        try{
            let tokendata = VueJwtDecode.decode(saved_token)
            let now = Date.now()/1000
            if(saved_token && tokendata.exp > now){
              this.loggedIn = true
              this.token = saved_token
                return true              }
              else{
                localStorage.clear()
                return false
              }
            }catch{
              return false
            }
        },
    expirationCheck(){
        let now = Date.now()/1000
        let expiration = VueJwtDecode.decode(this.token)
        if(expiration.exp > now){
            return true
        }else{
            this.userLogout()
        }
        }
    }

})

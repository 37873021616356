import { useUserStore } from "@/store/UserStore"

export const uploadMixin = {
    setup() {
        // create a constant for the store being used, and return it
          const userStore = useUserStore() 
          return { userStore }
      },
    data() {
    
    },
    computed: {
    
    },
    provide() {
        return{
            sendUpload: this.sendUpload,
            sendExemption: this.sendExemption
        }
    },
    methods: {
    // upload single data file
        async sendUpload(obj, params){
        try{
            let formData = new FormData()
            formData.append('file', obj.file)
            let resp = await fetch(process.env.VUE_APP_API_URL + 'projects/' + params.project + '/instruments/' + params.instrument + '/upload', {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + this.userStore.token
                },
                body: formData
            })
            return resp
        }catch{
            return "Error"
        }
        },
        async sendExemption(params){ //takes projectId and exemptionId
            try{
                let resp = await fetch(process.env.VUE_APP_API_URL + 'projects/' + params.projectId + '/exemptions/' + params.exemptionId, {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + this.userStore.token
                    }
                })
                return resp
            }catch{
                return "Error"
            }
        }
    },
}

   

import Vue from 'vue'
import { useUserStore } from '@/store/UserStore'
import VueRouter from 'vue-router'
import homeLogin from '../views/Home.vue'
import uploadPage from '../views/UploadPage.vue'
import adminOverview from '../views/AdminOverview.vue'
import monthlyOverview from '../views/MonthlyOverview.vue'
import adminHistory from '../views/AdminHistory.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: homeLogin,
    meta: {
      auth: false
    }
  },
  {
    path: '/upload',
    name: 'upload',
    component: uploadPage,
    meta: {
      auth: true
    }
  },
  {
    path: '/adminoverview',
    name: 'adminOverview',
    component: adminOverview,
    meta: {
      auth: true
    }
  },
  {
    path: '/history',
    name: 'history',
    component: adminHistory,
    meta: {
      auth: true
    }
  },
  {
    path: '/monthlyoverview/:project',
    name: 'monthlyOverview',
    component: monthlyOverview,
    meta: {
      auth: true
    }
  },
  {
    path: '/monthlyoverview',
    name: 'monthlyOverviewblank',
    component: monthlyOverview,
    meta: {
      auth: true
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const userStore = useUserStore()
  const loggedIn = userStore.loggedIn
  if(!loggedIn && to.meta.auth) {
    next({name: 'home'})
  } else {
    next();
  }
})

export default router

import {defineStore} from 'pinia'

export const useUploadStore = defineStore({
    id:'upload',
    inject: ['sendUpload'],
    state: () =>({
        currentProject: {},
    }),
    getters: {},
    actions: {
        
    async uploadData(file, params){
        let resp = await this.sendUpload(file, params)
        if(resp.status == 200){
            this.buttonStatus = 'mdi-check-bold'
            this.buttonText = "Success!"
        }else{
            this.buttonStatus = 'mdi-file-document-alert'
            this.buttonText = "Error!"
    }
    }
    }

})

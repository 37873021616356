<template>
  <v-container fluid class="pa-8">
    <v-row justify="center">
      <v-col cols="12">
        <v-card
          class="my-2">
          <v-card-title class="d-flex flex-row">
            <v-icon left>
              mdi-account-group
            </v-icon>
            Monthly Overview for {{ currentProject.project_name}}
            <v-spacer></v-spacer>
            <v-btn
            @click='back'
            >Back</v-btn>
          </v-card-title>

          <v-card-subtitle >
 
          </v-card-subtitle>

         </v-card>
          <v-simple-table>
          <thead>
            <tr>
              <th>Instrument Name</th>
              <th>Current Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr is='instrument-row'
              v-for="instrument in currentMonth.instrument_list"
              :key="instrument.inst_guid"
              :value="instrument"
              :project="currentProject.project_guid"
              >
            </tr>
          </tbody>
        </v-simple-table>
        
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import {useProjectsStore} from '@/store/Projects.js'


export default {
  components: {   },
  name: "monthlyOverview",
  setup() {
    // create a constant for the store being used, and return it
      const projectStore = useProjectsStore() 
      return { projectStore }
  },
  props: {
    value: {
      type: Object
    }
  },
  data() {
    return {
      currentProject: {"monthly_log": []},
      currentMonth: {},
      statusIcons: {
        Uploaded: 'mdi-account-check',
        Pending: 'mdi-account-question',
      },
      loading: false,
      selectedFiles: [],
      dialog: false,
    }
  },
  computed: {
    activeInstruments() {
      let instruments = []
      let currentList = this.currentProject.monthly_log[0]?.instrument_list || []
      // console.log(currentList)
      for (let i=0; i < currentList.length; i++ ) {
        if(currentList[i].active){
          instruments.push(currentList[i])
        }
      }
      return instruments
    },
    monthSelections() {
      let months = []
      for( let i=0; i<this.currentProject.monthly_log.length; i++){
        let newPeriod = this.currentProject.monthly_log[i].period
        months.push(newPeriod)
      }
      // console.log(months)
      return months
    },
    exemptionTrue() {
      if(this.currentMonth.exemption_option){
        return true
      }else{
        return false
      }
    }
  },
  methods: {
    setCurrentMonth(period){
      // console.log(period)
      this.currentMonth = this.currentProject.monthly_log.find(monthly_log => monthly_log.period == period)
    },
    setCurrentProject(guid){
      this.currentProject = this.projectList.find( project => project.project_guid == guid)
    },
    back(){
      this.$router.go(-1)
    },
    testClick() {
      // console.log(this.currentMonth)
    },
  },
  watch: {

  },
  mounted() {
    this.projectList = this.projectStore.projects
    // console.log(this.$route.params)
    if(this.$route.params.project){
    this.currentProject = this.projectList.find( project => project.project_guid == this.$route.params.project)
    }else{
      this.currentProject = this.projectList[0]
    }
    this.setCurrentMonth(this.currentProject.monthly_log[this.currentProject.monthly_log.length -1].period)
  },
}
</script>

<style>


</style>
import {defineStore} from 'pinia'
import { useUserStore } from './UserStore'

export const useProjectsStore = defineStore({
    id:'projects',
    state: () =>({
        projects: [],
        options: [],
        adminData: [
            
        ],
        instrument_logs: [
            
        ],
    }),
    getters: {},
    actions: {
   
    async getProjectList(){
        console.log('calling for the project list')
        const userStore = useUserStore()
        try {
            let resp = await fetch(process.env.VUE_APP_API_URL + 'projects', {
                method: 'GET',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + userStore.token
                }),
            })
            let array = await resp.json()
            this.projects = array.sort(
                (a, b) => {
                    const nameA = a.portal_pi.toUpperCase()
                    const nameB = b.portal_pi.toUpperCase()
                    if (nameA < nameB) {
                        return -1;
                      }
                      if (nameA > nameB) {
                        return 1;
                      }
                      return 0;
                }
            )
            
        } catch (error) {
            console.log(error)
            return false
        }
    },
    async getOptions(){
        console.log('calling for the options')
        const userStore = useUserStore()
        try {
            let resp = await fetch(process.env.VUE_APP_API_URL + 'options', {
                method: 'GET',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + userStore.token
                }),
            })
            let array = await resp.json()
            this.options = array[0].options['exemption_reason']
        } catch (error) {
            console.log(error)
            return false
        }
    },
    async getInstrumentLogs(){
        const userStore = useUserStore()
        try {
            let resp = await fetch(process.env.VUE_APP_API_URL + 'instrument_log', {
                method: 'GET',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + userStore.token
                }),
            })
            this.instrument_logs = await resp.json()
            return this.instrument_logs
        } catch (error) {
            console.log(error)
            return false
        }
    } 
    }

})

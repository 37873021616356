<template>
    <v-container fluid class="pa-8">
      <v-row justify="center">
        <v-col cols="12">
          <v-card
            class="my-2">
            <v-card-title class="d-flex flex-row">
              <v-icon left>
                mdi-account-group
              </v-icon>
              Historical Data Upload
              <v-spacer></v-spacer>
              <v-btn @click="$router.go(-1)">Back</v-btn>
            </v-card-title>
  
            <v-card-subtitle >
   
            </v-card-subtitle>
           </v-card>
            <v-simple-table>
            <thead>
              <tr>
                <th></th>
                <th v-for="project in projectStore.projects" :key="project.project_guid">
                  {{ project.portal_pi }}
                </th>
              </tr>
            </thead>
            <tbody>
              <template
                v-for="month in dataByMonth">
              <tr is='history-row'
                :key="month.period"
                :value="month.data"
                :period="month.period"
                >
              </tr>
            </template>
            </tbody>
          </v-simple-table>
          
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  
  import {useProjectsStore} from '@/store/Projects.js'
  import historyRow from '@/components/overviews/historyRow.vue'
  
  
  export default {
    components: { historyRow },
    name: "adminHistory",
    setup() {
      // create a constant for the store being used, and return it
        const projectStore = useProjectsStore() 
        return { projectStore }
    },
    data() {
      return {
        dataByMonth: []
      }
    },
    computed: {
     
    },
   
    methods: {
        click(){
          console.log('array of data by month', this.dataByMonth)
        },
  
        formatDataByMonth() {
        let finalArray = []
        for(let i=1;i<=5;i++){ //loop for each period
          let monthArray = []
          let monthInsert = {}
          let period = this.projectStore.projects[0].monthly_log[this.projectStore.projects[0].monthly_log.length - i].period
          for(let j=0;j<this.projectStore.projects.length;j++){ //loop for getting monthly log for each project in that period
            let project = this.projectStore.projects[j]
            let log = project.monthly_log[project.monthly_log.length - i]
            log = Object.assign(log, {project_guid: project.project_guid})
            monthArray.push(log)
          }
          monthInsert.period = period
          monthInsert.data = monthArray
          finalArray.push(monthInsert)
        }
        this.dataByMonth = finalArray
      }

    },
    watch: {
  
    },
    async mounted() {
      this.formatDataByMonth()
    },
  }
  </script>
  
  <style>
  
  
  </style>
<template>
  <v-dialog
    v-model="visible"
    minWidth="500"  
    max-width="900"
  >
  <template  v-slot:activator="{ on }">
    <v-btn small class="ml-1" v-on="on" @click='getLog'>View Log</v-btn>
    </template>
    <v-card>
        <v-card-title>
            <v-icon left>
                mdi-clipboard-text-clock
            </v-icon>
            History for {{ this.value.inst_name }}
        </v-card-title>
        <v-simple-table
            fixed-header
            height="300px"
            class="mx-6"
        >
        <template>
            <thead>
                <td>Upload Date</td>
                <td>PI</td>
                <td>Status</td>
                <td>Cumulative Subjects</td>
            </thead>
            <tbody>
                <tr
                    v-for="item in this.currentLog"
                    :key="item.log_datetime"
                    >
                    <td>{{ item.log_datetime }}</td>
                    <td>{{ item.log_pi }}</td>
                    <td>{{ item.log_stats }}</td>
                    <td>{{ item.log_ct }}</td>
                    </tr>
            </tbody>
        </template>

        </v-simple-table>
        <v-card-actions
            class="justify-end">
            <v-btn
            color="primary"
            @click="closeDialog">Close</v-btn>
        </v-card-actions>
    </v-card>
    </v-dialog> 
</template>

<script>

import {useProjectsStore} from '@/store/Projects.js'

// DETERMINE LOG FILE DATABASE FORMATTING
export default {
    name: "instrumentLogDisplay",
    setup() {
    // create a constant for the store being used, and return it
      const projectStore = useProjectsStore() 
      return { projectStore }
  },
    props: {
        value: {
            type: Object
        },
        project: {
            type: String
        },

    },
    data: () => {
        return {
            currentLog: [{}],
            visible: false
        }
    },
    computed: {

    },
    methods: {
        closeDialog(){
            this.visible = false
        },
        getLog() { 
            this.visible = true
            let projectLog = [{}]
            let instLog = [{}]
            projectLog = this.projectStore.instrument_logs.find(proj => proj.project_guid == this.project)
            instLog = projectLog.inst_list.find(inst => inst.inst_name == this.value.inst_name)
            let sortedLog = instLog.inst_log.sort(function (a, b){
                let dateA = new Date(a.log_datetime)
                let dateB = new Date(b.log_datetime)
                return dateB - dateA
                })
            this.currentLog = sortedLog
}
    },
    mounted() {
        
    },


}

</script>

<style scoped>

</style>
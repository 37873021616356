<template>
  <v-dialog
    v-model="visible"
    minWidth="500"  
    max-width="1100"
  >
  <template  v-slot:activator="{ on }">
    <v-btn v-on="on">Create Exemption</v-btn>
    </template>
      <v-card>
        <v-card-title
          class="my-2">
          <h2>Create an Exemption</h2>
        </v-card-title>
        <v-select
          v-model="reason"
          :items="items"
          item-text="error_description"
          item-value="guid"
          label="Exemption Reason"
          dense
          outlined
          class="mx-6"
        ></v-select>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text
          @click="visible=false"
        >
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          @click="submit"
        >
          Submit
        </v-btn>
      </v-card-actions>
      </v-card>
  </v-dialog>    
</template>

<script>

  import {useProjectsStore} from '@/store/Projects.js'

  export default {
    name: "exemptionDialog",
    props: {
      value: {
        type: Boolean,
        default: false
      }
    },
    setup() {
      const projectStore = useProjectsStore() 
      return { projectStore }
  },
    data() {
      return {
        reason: ""
      }
    },
    computed: {
      items(){
        return this.projectStore.options
      },
    visible: {
      get() {return this.value},
      set(v) {this.$emit("input", v)}
    },
    },
    mounted() {

    },
    methods: {
      submit() {

        this.$emit("set-exemption", this.reason)
        this.visible = false // emit request to make the call?
      },
    },
    watch: {
    }
  }
</script>
<template>
  <v-container fluid class="pa-8">
    <v-row justify="center">
      <v-col cols="12">
        <v-card
          class="my-2">
          <v-card-title class="d-flex flex-row">
            <v-icon left>
              mdi-file-upload
            </v-icon>
            Data Upload for - {{ this.currentProject.project_name }}
            <v-spacer></v-spacer>
            
          </v-card-title>

          <v-card-subtitle >
            Submit data files for your project here
            
          </v-card-subtitle>

         </v-card>

        <v-simple-table>
          <thead>
            <tr>
              <th>Instrument Name</th>
              <th>Last Submission</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr is='instrument-upload'
              v-for="inst in currentProject.monthly_log[0]['instrument_list']"
              :key="inst.inst_guid"
              :value="inst"
              :project="currentProject.project_guid"
              @update-status="setResp"
              >
            </tr>
          </tbody>
        </v-simple-table>
        <v-card 
        class="pa-4 my-2">
          <v-card-actions>
            <exemptionDialog 
            v-model="exemptionDialog" 
            @set-exemption="setExemption"
            />
            <v-spacer></v-spacer>{{ exemptionResp }}<v-spacer></v-spacer><v-spacer></v-spacer>
            <!-- <v-btn
              dense
              max-width="200"
              label='Select Project'
                >
            <select max-width="200" v-model="currentProject" @change="setCurrentProject($event.target.value)">
              <option v-for="project in projectList" :value="project.project_name" :key="project.project_guid">{{ project.project_name }}</option>
            </select>
            </v-btn> -->
            <v-select
              v-model="selectedProject"
              :items="projectList"
              item-text="project_name"
              label="Select Project"
              return-object
              dense
              ></v-select>
          </v-card-actions>
        </v-card>
        
      </v-col>
      <v-overlay
        :value='submitWindowDisable'>
          Files must be submitted before the 15th of the month. Please return next month to upload your cumulative data.
          <v-btn class="lockout-button" @click="()=> {this.submitWindowDisable=false}">Close</v-btn>
        </v-overlay>
    </v-row>
  </v-container>
</template>

<script>

import {useProjectsStore} from '@/store/Projects.js'
import { useUploadStore } from '@/store/UploadStore'
import instrumentUpload from '@/components/instrumentUpload.vue'


export default {
  components: { instrumentUpload },
  name: "uploadPage",
  setup() {
    // create a constant for the store being used, and return it
      const projectStore = useProjectsStore() 
      const uploadStore = useUploadStore()
      return { projectStore, uploadStore }
  },
  inject: ['sendUpload', 'sendExemption'],
  data() {
    return {
      headers: [
        {
          text: "Instrument Name",
          align: "center",
          value: "inst_name"
        },
        {
          text: "Last Submission",
          align: "center",
          value: "inst_datetime"
        },
        {
          text: "Status",
          align: "center",
          value: "inst_status"
        },
        {
          text: "",
          align: "center",
          value: "upload"
        },
      ],
      selectedProject: {
        "project_name": "",
        "portal_pi": "",
        "project_guid": "X",
        "monthly_log": [{}]
      },
      buttonText: "Upload",
      buttonStatus: "",
      loading: false,
      selectedFiles: [],
      exemptionDialog: false,
      exemptionResp: "",
      submitWindowDisable: false
    }
  },
  computed: {
    projectList() {
      return this.projectStore.projects
    },
    activeInstruments() {
      return this.currentProject.monthly_log
    },
    currentProject() {
      return this.selectedProject
    }
    
  },
  methods: {
    setResp(e){
      for(let i = 0; i < this.currentProject.monthly_log[0]['instrument_list'].length; i++){
        if (this.currentProject.monthly_log[0]['instrument_list'][i]['inst_guid'] == e.inst_guid){
          this.currentProject.monthly_log[0]['instrument_list'][i]['inst_status'] = e.inst_status
          this.currentProject.monthly_log[0]['instrument_list'][i]['inst_datetime'] = e.inst_datetime
        }
      }
    },
    async setExemption(e){
      let params = Object.assign({}, {
        projectId : this.currentProject.project_guid,
        exemptionId : e
      })
      let resp = await this.sendExemption(params)
      if(resp.status == 200){
        this.exemptionResp = "Exemption Submitted"
      }else{
        this.exemptionResp = "Error in submitting, please try again"
      }
    },

    setCurrentProject(guid){
      this.selectedProject = this.projectList.find( project => project.project_guid == guid)
    },
    
    submitWindow(){
      let d = new Date
      d = d.getDate()
      if(d > 15){
        this.submitWindowDisable = true
      }
    }
  },
  watch: {

  },
  async mounted() {
    await this.projectStore.getProjectList()
    this.selectedProject = this.projectList[0]
    this.projectStore.getOptions()
    this.projectStore.getInstrumentLogs()
    this.submitWindow()
  }
}
</script>

<style>
.lockout-button{
  position: fixed;
  bottom: 20px;
  right: 20px;
}

</style>
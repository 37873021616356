<template>
  <v-app id="cifasd">
    <v-app-bar app color="primary" dark>
      <v-toolbar-title>
        CIFASD Data Upload Center
        <!-- <img id="titleLogo" @click="$router.push('/')" :src = "#" :height="$vuetify.breakpoint.mobile ? '40px' : '50px'" style="vertical-align: middle" alt="titlebar logo" /> -->
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <span v-if="loggedIn">
      <v-btn light
        class="mx-2"
        @click="logOut">Logout</v-btn>
      
      </span>
    </v-app-bar>
    <v-main id="main" :class="$vuetify.breakpoint.mobile ? 'logo' : 'no-logo'" :style="{background:$vuetify.theme.themes.light.secondary}">
      <router-view></router-view>
    </v-main>
    <!-- <p-disclaimer v-model="disclaimer"></p-disclaimer> -->
    <!-- <p-privacy-notice v-model="privacyNotice"></p-privacy-notice> -->
    <!-- <p-snackbar></p-snackbar> -->
    <v-footer app color="primary" dark>
      <v-spacer></v-spacer>
        <!-- <span class="mx-2" id="copyright">&#169;{{currentYear}} <span v-if="!$vuetify.breakpoint.mobile">Indiana University</span></span>
          | 
        <span @click="privacyNotice = true" class="fake-link mx-2" id="privacyNoticeLink">Privacy Notice</span> 
          | 
        <span @click="disclaimer = true" class="fake-link mx-2" id="disclaimerLink">Disclaimer</span> 
      <v-spacer></v-spacer>
      <span id="version" v-if="!$vuetify.breakpoint.mobile" style="position: absolute; font-size: 0.7em; color: #DDD">v{{appVersion}}</span> -->
    </v-footer>
  </v-app>
</template>

<script>
import { uploadMixin } from './mixins/uploadMixin';
import {useProjectsStore} from '@/store/Projects.js';
import { useUserStore } from '@/store/UserStore.js';

export default {
  name: 'App',
  setup() {
      const projectStore = useProjectsStore() 
      const userStore = useUserStore()
      return { projectStore, userStore }
  },
  mixins: [uploadMixin],
  data: () => ({
    disclaimer: false,
    privacyNotice: false,
  }),
  computed: {
    loggedIn() {
      return (this.userStore.token)
    },
  },
  methods: {
    async logOut(){
      await this.userStore.userLogout()
    }
  }
};
</script>
<style>
  .logo {
    background-size: cover;
    background-color: #fff;
  }

  .no-logo {
    background-size: 50%;
    background-color: #eee;    
  }

  .fake-link {
    cursor: pointer;
    text-decoration: underline;
  }

  .v-btn {
    font-family: 'freight-sans-pro', sans-serif;
  }
  </style>
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);



export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: '#510C02',
            primaryLight: "#8f1504",
            primaryDark: "#280601",
            secondary: '#F4F4F4',
            accent: '#373737', 
          }
        }
      }
});

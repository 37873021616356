import Vue from 'vue'

import "./dialogs"
import cardtitle from '@/components/cardTitle.vue'
import instrumentUpload from "./instrumentUpload.vue"
import overviewRow from "./overviews/overviewRow.vue"
import instrumentRow from "./overviews/instrumentRow.vue"

const components = [cardtitle, instrumentUpload, overviewRow, instrumentRow]

components.forEach(component => {
  Vue.component(component.name, component)
})
<template>
    <tr>
        <td>
         {{ period }}
        </td>
        <template 
          v-for="project in this.projectList">
          <td is="history-cell"
            :key="project.project_guid"
            :value="project">
          </td>
        </template>
    </tr>
  </template>
  
  <script>
  
  import historyCell from '@/components/overviews/historyCell.vue'

  export default {
    components: {  historyCell },
    name: "historyRow",
    props: {
      value: {
        type: Array,
        required: true
      },
      period: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        
      }
    },
    computed: {
      projectList(){
        return this.value
      }
    }
}
</script>
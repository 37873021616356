<template>
  <v-container fluid class="pa-8">
    <v-row justify="center">
      <v-col cols="12">
        <v-card
          class="my-2">
          <v-card-title class="d-flex flex-row">
            <v-icon left>
              mdi-account-group
            </v-icon>
            Projects Overview
            <v-spacer></v-spacer>
            <v-btn @click="$router.push('history')">History</v-btn>
          </v-card-title>

          <v-card-subtitle >
 
          </v-card-subtitle>

         </v-card>
          <v-simple-table>
          <thead>
            <tr>
              <th>Project Name</th>
              <th>Project PI</th>
              <th>Current Monthly Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr is='overview-row'
              v-for="project in this.projectStore.projects"
              :key="project.project_guid"
              :value="project"
              >
            </tr>
          </tbody>
        </v-simple-table>
        
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import {useProjectsStore} from '@/store/Projects.js'
import overviewRow from '@/components/overviews/overviewRow.vue'


export default {
  components: { overviewRow },
  name: "adminOverview",
  setup() {
    // create a constant for the store being used, and return it
      const projectStore = useProjectsStore() 
      return { projectStore }
  },
  data() {
    return {
      projectList: [],
      adminList: [],
      loading: false,
      dialog: false,
      displayMonth: null
    }
  },
  computed: {
    monthOptions(){

      return null
    }
  },
 
  methods: {
      

  },
  watch: {

  },
  async mounted() {

    await this.projectStore.getProjectList()
    await this.projectStore.getInstrumentLogs()
  },
}
</script>

<style>


</style>
<template>
  <tr>
    
      <td>
       {{value.inst_name}}
      </td>
        <td>
        {{value.inst_status}}
      </td>
        <td>
          <span class="d-flex flex-row align-center">
                 <instrument-log-display :value="value" :project="project" /> 
          </span>
      </td>
   
  </tr>
</template>

<script>


export default {
  components: {   },
  name: "instrumentRow",
  setup() {

  },
  inject: [],
  props: {
    value: {
      type: Object
    },
    project: {
      type: String
    }

  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {

  },
  methods: {

    
  },
  watch: {
    
  },
  mounted() {

  },
}
</script>

<style>


</style>
<template>
  <tr>
    
      <td>
       {{value.project_name}}
      </td>
      <td>
        {{ value.portal_pi }}
      </td>
      <v-tooltip bottom v-if="exemption">
        <template v-slot:activator="{ on, attrs }">
          <td 
            :class="statusColor"
            v-bind="attrs"
            v-on="on"
          >
          <span>Exemption Submitted</span></td>
        </template>
      <span>{{ this.exemption_option }}</span>
      </v-tooltip>
      <td :class="statusColor" v-else>
        {{ this.statusText }} <v-icon>{{ this.statusIcon }}</v-icon>
      </td>
      <td>
        <span class="d-flex flex-row align-center">
          <v-btn
            small
            class="ml-1"
            @click="getDetails"
          >View Details</v-btn>
        </span>
      </td>
   
  </tr>
</template>

<script>


export default {
  components: {   },
  name: "overviewRow",
  setup() {

  },
  inject: [],
  props: {
    value: {
      type: Object
    }
  },
  data() {
    return {
      statusColor: "",
      statusIcon: "",
      statusText: "",
      exemption: false,
      exemption_option: "",
    }
  },
  computed: {
    
  },
  methods: {
    projectStatus(){
      if(this.value.close_date){
        this.statusColor = "status-closed"
        this.statusIcon = ""
        this.statusText = "Project Closed"
        return
        }
      let success = 0
      if(this.value.monthly_log[this.value.monthly_log.length -1].exemption_option){
        this.statusColor = "status-partial"
        this.statusIcon = "mdi-file-document-alert"
        this.statusText = `Exemption Submitted`
        this.exemption_option = this.value.monthly_log[this.value.monthly_log.length -1].exemption_option
        this.exemption = true
        return
      }
      let current_list = this.value.monthly_log[this.value.monthly_log.length -1].instrument_list
      if (current_list[0].inst_name == null){
        this.statusColor = "status-closed"
        this.statusIcon = ""
        this.statusText = "Project Pending"
        return
        }
      
      for(let i=0; i<current_list.length; i++){
          if(current_list[i].inst_status == 'Validated'){
            success += 1
          }
        }
      let totalInst = current_list.length
      if(success == totalInst){
        this.statusColor = "status-success"
        this.statusIcon = "mdi-check-bold"
        this.statusText = `${success}/${totalInst} Successful Uploads`
        }else{
          this.statusColor = "status-partial"
          this.statusIcon = "mdi-file-document-alert"
          this.statusText = `${success}/${totalInst} Successful Uploads`
          if(success == 0){
            this.statusColor = "status-error"
          }
        }
      },

    getDetails(){
        this.$router.push({name: "monthlyOverview", params: { project: this.value.project_guid}})
    },
  },

  mounted() {
    this.projectStatus()
  },
}
</script>

<style>
  
  .status-success {
    background-color: rgb(128, 187, 128);
  }
  .status-error {
    background-color: rgb(236, 124, 124);
  }
  .status-closed {
    background-color: rgb(192, 192, 192);
  }
  .status-partial {
    background-color: rgb(255, 255, 129);
  }

</style>
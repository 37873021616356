<template>
  <v-container>
    <v-row justify="center">
      <v-col cols=12 sm=8 md=6 lg=4 xl=3>
        <v-card id="welcome" elevation=10 rounded="lg" :style="{background:$vuetify.theme.themes.light.secondary}">
          <p-card-title
            title="Welcome!"
            icon="mdi-human-greeting-variant"
          ></p-card-title>
          <v-card-text >
            <p>
              Login here to submit your data.
              </p>
                <form ref="form" @submit.prevent="login()">
                       <v-text-field
                         v-model="username"
                         name="username"
                         label="Username"
                         type="text"
                         placeholder="username"
                         required
                      ></v-text-field>
                     
                      <v-text-field
                        v-model="password"
                        name="password"
                        label="Password"
                        type="password"
                        placeholder="password"
                        required
                     ></v-text-field>
                    
                </form>
            </v-card-text>
            <v-card-actions>
              <v-row flex dense>
              <v-col>
                <v-btn block color="primary" class="my-2" @click="login">Login<v-icon right>mdi-chevron-right</v-icon></v-btn>
              </v-col>
              </v-row>
            </v-card-actions>     
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import {useUserStore} from '@/store/UserStore.js'
import VueJwtDecode from 'vue-jwt-decode'

export default {
  name: "homeLogin",
  setup() {
    // create a constant for the store being used, and return it
      const userStore = useUserStore() 
      return { userStore }
  },
  data: () => ({
    username: "",
    password: ""
  }),
  computed: {
//
  },
  methods: {
    async login(){
      let userdata = this.username + ':' + this.password
      let authheader = 'Basic ' + btoa(userdata)
      let login = await this.userStore.userLogin(authheader)
      if(login){
        let decoded = VueJwtDecode.decode(login)
        if(decoded.group.includes('CFASD_Admin')){
          this.$router.push('/adminoverview')
        }else{
          this.$router.push('/upload')
        }
      }else{
        alert('error logging in!')
      }
    }
    
  },
  mounted() { 

  let saved_token = localStorage.getItem('cifasd_token')
  try{
  let tokendata = VueJwtDecode.decode(saved_token)
  let now = Date.now()/1000
  if(saved_token && tokendata.exp > now){
    this.userStore.loggedIn = true
    this.userStore.token = saved_token
    let decoded = VueJwtDecode.decode(saved_token)
        if(decoded.group.includes('CFASD_Admin')){
          this.$router.push('/adminoverview')
        }else{
          this.$router.push('/upload')
        }
    }
    else{
      localStorage.clear()
    }
  }catch{
    return
  }
  },
  
}
</script>

<style>

</style>
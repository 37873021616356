<template>
  <tr>
    
      <td>
       {{value.inst_name}}
      </td>
        <td>
        {{value.inst_datetime}}
      </td>
        <td>
        {{value.inst_status}}
      </td>
        <td>
          <span class="d-flex flex-row align-center">
                <v-file-input
                  small
                  truncate-length="15"
                  :instrument="value"
                  @change="fileSelected($event, value)">
                  Select File
                  </v-file-input>
                <v-btn
                  :instrument="value"
                  small
                  class="ml-1"
                  @click="uploadInstrument(value)"
                ><v-icon>{{ buttonStatus }}</v-icon>{{ buttonText }}</v-btn>
          </span>
      </td>
   
  </tr>
</template>

<script>

import {useUserStore} from '@/store/UserStore.js'

export default {
  components: {   },
  name: "instrumentUpload",
  setup() {
      const userStore = useUserStore() 
      return { userStore }
  },
  inject: ['sendUpload'],
  props: {
    value: {
      type: Object
    },
    project: {
      type: String
    }
  },
  data() {
    return {
      statusIcons: {
        Uploaded: 'mdi-account-check',
        Pending: 'mdi-account-question',
      },
      loading: false,
      selectedFile: {},
      dialog: false,
      buttonText: "Upload",
      buttonStatus: "",
    }
  },
  computed: {

  },
  methods: {
    fileSelected($event, item){ 
      let payload = {
        "file": $event,
        "name": item.inst_name
      }
      this.selectedFile = payload
    },
    async uploadInstrument(item){
      this.buttonStatus = 'mdi-progress-upload'
      this.buttonText = "In Progress"
      let file = this.selectedFile
      let params = {
        'project': this.project,
        'instrument': item.inst_guid
      }
      let tokenCheck = await this.userStore.expirationCheck()
      if(tokenCheck){
        let resp = await this.sendUpload(file, params)
        if(resp.status == 200){
            this.buttonStatus = 'mdi-check-bold'
            this.buttonText = "Uploaded!"
            this.$emit("update-status", await resp.json())
        }else{
          this.buttonStatus = 'mdi-file-document-alert'
          this.buttonText = "Error!"
      }}
    },
  },
  watch: {
    selectedFile(){
      this.buttonText = "Upload"
      this.buttonStatus = ""
    }
  },
  mounted() {

  },
}
</script>

<style>


</style>
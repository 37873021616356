import Vue from 'vue'


import exemptionDialog from "./exemptionDialog.vue"
import instrumentLogDisplay from "./instrumentLogDisplay.vue"

let components = [exemptionDialog, instrumentLogDisplay]

components.forEach(component => {
    Vue.component(component.name, component)
})